<template>
    <Setup-Step
        @yes="f => addNew()"
        @no="f => f()"
        :customizeTo="stage == 0 ? { name: 'customer-templates' } : null"
        :fluidContent="stage > 9 && stage < 18"
        :loadingMsg="loadingMsg"
        :settings="settings"
        :stage.sync="stage"
        step="customer-templates"
        title="Customer Templates">
        <template v-slot:body>
            <v-tabs-items v-model="stage" touchless>
                <v-tab-item>
                    <Setup-Block>
                        <BT-List-Endless
                            :canSearch="false"
                            :loadingMsg.sync="loadingMsg"
                            maxHeight="60vh"
                            navigation="customer-templates"
                            :refreshToggle="refreshList">
                            <template v-slot="{ item }">
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.templateName }}</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn small text @click="addNew(item)">change</v-btn>
                                </v-list-item-action>
                            </template>
                        </BT-List-Endless>

                        <Setup-More :settings="settings">
                            <p>
                                Customer templates provide a way to define the default settings for new customers.  You can also change the settings of all customers that are attached to a certain template in one action.
                            </p>
                            <p>
                                We highly recommend starting with at least one template so you can define what settings your customers begin with.
                            </p>
                        </Setup-More>
                        
                        <p class="my-4">Would you like to add a template?</p>
                    </Setup-Block>
                </v-tab-item>

                <v-tab-item>
                    <Setup-Block>
                        <BT-Field-String
                            label="Template Name"
                            v-model="newItem.templateName"
                            isEditing />
                        <BT-Btn 
                            buttonClass="primary mx-1 mt-4"
                            inline
                            label="Next"
                            @click="stage += 1" />
                    </Setup-Block>
                </v-tab-item>

                <v-tab-item>
                    <Setup-Block>
                        <p>Will this customer have default payment terms?</p>

                        <BT-Select-List-Box
                            @change="stage += 1"
                            v-model="newItem.paymentTermsID"
                            height="35vh"
                            isEditing
                            isSelectingNullValue
                            itemText="paymentTermsName"
                            label="Payment Terms"
                            :multiple="false"
                            navigation="supplier-payment-terms" />

                        <BT-Btn
                            buttonClass="primary mx-1 mt-4"
                            inline
                            label="Next"
                            @click="stage += 1" />
                    </Setup-Block>
                </v-tab-item>

                <v-tab-item>
                    <Setup-Block>
                        <p>Will this customer have a default pricing tier?</p>

                        <BT-Select-List-Box
                            @change="stage += 1"
                            v-model="newItem.priceTierID"
                            height="35vh"
                            isEditing
                            isSelectingNullValue
                            itemText="priceTierName"
                            label="Price Tier"
                            :multiple="false"
                            navigation="supplier-price-tier" />

                        <BT-Btn
                            buttonClass="primary mx-1 mt-4"
                            inline
                            label="Next"
                            @click="stage += 1" />
                    </Setup-Block>
                </v-tab-item>

                <v-tab-item>
                    <Setup-Block>
                        <p>Do you want to limit when this customer can get deliveries and when they need to order by?</p>

                        <BT-List-Item-Check
                            class="my-3"
                            :falseValue="null"
                            subtitle="Customers can order whenever they want and their delivery date can be set to a certain lead time."
                            title="Open Ordering"
                            trueValue="Open"
                            v-model="newItem.orderingArrangement" />

                        <BT-List-Item-Check
                            class="my-3"
                            :falseValue="null"
                            subtitle="Control what days this customer can get deliveries and when the need to have their order placed by."
                            title="Slotted Ordering"
                            trueValue="Slotted"
                            v-model="newItem.orderingArrangement" />

                        <BT-Btn
                            buttonClass="primary mx-1 mt-4"
                            inline
                            label="Next"
                            @click="nextOrderingStyle" />
                    </Setup-Block>
                </v-tab-item>

                <v-tab-item>
                    <Setup-Block>
                        <p>Can this customer get deliveries on weekends?</p>

                        <BT-List-Item-Check
                            class="my-3"
                            subtitle="Can get deliveries on weekends."
                            title="Available Weekends"
                            v-model="newItem.isAvailableWeekends" />

                        <BT-List-Item-Check
                            class="my-3"
                            :falseValue="true"
                            subtitle="Only deliver on weekdays."
                            title="Only Deliver On Weekdays"
                            :trueValue="false"
                            v-model="newItem.isAvailableWeekends" />
                            
                        <BT-Btn
                            buttonClass="primary mx-1 mt-4"
                            inline
                            label="Next"
                            @click="stage += 1" />
                    </Setup-Block>
                    
                </v-tab-item>

                <v-tab-item>
                    <Setup-Block>
                        <p>Do you require a lead time for this customer?</p>

                        <BT-List-Item-Check
                            class="my-3"
                            subtitle="Require an order lead time."
                            title="Require Lead Time"
                            v-model="newItem.requireLeadTime" />

                        <BT-List-Item-Check
                            class="my-3"
                            :falseValue="true"
                            subtitle="No lead time will mean this customer can place orders for immediate delivery."
                            title="No Lead Time Necessary"
                            :trueValue="false"
                            v-model="newItem.requireLeadTime" />
                            
                        <BT-Btn
                            buttonClass="primary mx-1 mt-4"
                            inline
                            label="Next"
                            @click="stage += 1" />
                    </Setup-Block>
                    
                </v-tab-item>

                <v-tab-item>
                    <Setup-Block>
                        <p>How many hours of lead time?</p>

                        <BT-Field-Number
                            v-model.number="newItem.orderLeadHours"
                            label="Hours"
                            isEditing />

                        <BT-Btn
                            buttonClass="primary mx-1"
                            inline
                            label="Next"
                            @click="stage += 2" />
                    </Setup-Block>
                </v-tab-item>

                <v-tab-item>
                    <Setup-Block>
                        <p>
                            You can limit how many orders or days this customer can place in advance.  
                            You might want to limit a customer to only placing 3 orders in advance.  
                            Or you might want to limit a customer to placing orders no more than 14 days away.
                        </p>

                        <v-row>
                            <v-col cols="6">
                                <BT-Square-Number
                                    v-model.number="newItem.scheduleOrderLimit"
                                    isEditing
                                    label="Future Order Limit"
                                    type="number" />
                            </v-col>
                            <v-col cols="6">
                                <BT-Square-Number
                                    v-model.number="newItem.scheduleDayLimit"
                                    isEditing
                                    label="Future Day Limit"
                                    type="number" />
                            </v-col>
                        </v-row>

                        <BT-Btn
                            buttonClass="primary mx-1 mt-4"
                            inline
                            label="Next"
                            @click="setScheduleLimits" />
                    </Setup-Block>
                </v-tab-item>

                <v-tab-item>
                    <Setup-Block>
                        <p>Would you like to allow this customer to place their own orders?</p>

                        <BT-List-Item-Check
                            class="my-3"
                            subtitle="Any customers with live accounts can place their own orders."
                            title="Enable Ordering"
                            v-model="newItem.isCustomerOrderingEnabled" />

                        <BT-List-Item-Check
                            class="my-3"
                            :falseValue="true"
                            subtitle="Customers can have live accounts where they view orders and invoices, but they can't place new orders."
                            title="Disable Customer Ordering"
                            :trueValue="false"
                            v-model="newItem.isCustomerOrderingEnabled" />
                            
                        <BT-Btn
                            buttonClass="primary mx-1 mt-4"
                            inline
                            label="Next"
                            @click="stage += 1" />
                    </Setup-Block>
                    
                </v-tab-item>

                <v-tab-item>
                    <BT-Order-Cycle-Header showTrimmings highlightApprove title="Order Pipeline: Approve" />

                    <v-divider class="my-4" />
                    
                    <Setup-Block>
                        <p>When a customer places an order, do you want BlitzIt Web to automatically approve the order?</p>

                        <p>(This only applies if you've decided to define this for individual customers rather than globally for everyone)</p>

                        <v-row>
                            <v-col cols="12" md="6" lg="4">
                                <BT-Square-Check
                                    falseClass="success--text"
                                    falseIcon="mdi-boom-gate"
                                    falseValue="Manual"
                                    isEditing
                                    :label="newItem.approvalTrigger"
                                    outlined
                                    trueIcon="mdi-boom-gate-up"
                                    trueValue="Auto"
                                    v-model="newItem.approvalTrigger" />
                            </v-col>
                        </v-row>
                        
                        <BT-Btn
                            buttonClass="primary mx-1 mt-4"
                            inline
                            label="Next"
                            @click="stage += 1" />
                    </Setup-Block>
                    
                </v-tab-item>

                <v-tab-item>
                    <BT-Order-Cycle-Header showTrimmings highlightFulfill title="Order Pipeline: Fulfill" />

                    <v-divider class="my-4" />
                    
                    <Setup-Block>
                        <p>After being approved, do you want BlitzIt Web to automatically select who is responsible for fulfilling the order?</p>

                        <p>(This only applies if you've decided to define this for individual customers rather than globally for everyone)</p>

                        <v-row>
                            <v-col cols="12" md="6" lg="4">
                                <BT-Square-Check
                                    falseClass="success--text"
                                    falseIcon="mdi-boom-gate"
                                    falseValue="Manual"
                                    isEditing
                                    :label="newItem.fulfillerTrigger"
                                    outlined
                                    trueIcon="mdi-boom-gate-up"
                                    trueValue="Auto"
                                    v-model="newItem.fulfillerTrigger" />
                            </v-col>
                        </v-row>
                        
                        <BT-Btn
                            buttonClass="primary mx-1 mt-4"
                            inline
                            label="Next"
                            @click="stage += 1" />
                    </Setup-Block>
                    
                </v-tab-item>
                
                <v-tab-item>
                    <BT-Order-Cycle-Header showTrimmings highlightProcess title="Order Pipeline: Process" />

                    <v-divider class="my-4" />
                    
                    <Setup-Block>
                        <p>Once you have been assigned to fulfill an order, do you want BlitzIt Web to automatically process the order into the next stage in the pipeline?</p>

                        <p>(This only applies if you've decided to define this for individual customers rather than globally for everyone)</p>

                        <v-row>
                            <v-col cols="12" md="6" lg="4">
                                <BT-Square-Check
                                    falseClass="success--text"
                                    falseIcon="mdi-boom-gate"
                                    falseValue="Manual"
                                    isEditing
                                    :label="newItem.processTrigger"
                                    outlined
                                    trueIcon="mdi-boom-gate-up"
                                    trueValue="Auto"
                                    v-model="newItem.processTrigger" />
                            </v-col>
                        </v-row>
                        
                        <BT-Btn
                            buttonClass="primary mx-1 mt-4"
                            inline
                            label="Next"
                            @click="stage += 1" />
                    </Setup-Block>
                    
                </v-tab-item>
                
                <v-tab-item>
                    <BT-Order-Cycle-Header showTrimmings highlightStockConsign title="Order Pipeline: Stock Consignment" />

                    <v-divider class="my-4" />
                    
                    <Setup-Block>
                        <p>
                            Once processed, orders become stock consignments which need to be assigned a supply location.  
                            You can do this automatically or manually.
                        </p>

                        <p>(This only applies if you've decided to define this for individual customers rather than globally for everyone)</p>

                        <v-row>
                            <v-col cols="12" md="6" lg="4">
                                <BT-Square-Check
                                    falseClass="success--text"
                                    falseIcon="mdi-boom-gate"
                                    falseValue="Manual"
                                    isEditing
                                    :label="newItem.consignmentTrigger"
                                    outlined
                                    trueIcon="mdi-boom-gate-up"
                                    trueValue="Auto"
                                    v-model="newItem.consignmentTrigger" />
                            </v-col>
                        </v-row>
                        
                        <BT-Btn
                            buttonClass="primary mx-1 mt-4"
                            inline
                            label="Next"
                            @click="stage += 1" />
                    </Setup-Block>
                    
                </v-tab-item>
                
                <v-tab-item>
                    <BT-Order-Cycle-Header showTrimmings highlightStockConsign title="Order Pipeline: Stock Consignment" />

                    <v-divider class="my-4" />
                    
                    <Setup-Block>
                        <v-subheader>Stock Consignment</v-subheader>

                        <p>
                            Once processed, orders become stock consignments which need to be assigned a supply location.  
                            You can do this automatically or manually.
                        </p>

                        <p>(This only applies if you've decided to define this for individual customers rather than globally for everyone)</p>

                        <v-row>
                            <v-col cols="12" md="6" lg="4">
                                <BT-Square-Check
                                    falseClass="success--text"
                                    falseIcon="mdi-boom-gate"
                                    falseValue="Manual"
                                    isEditing
                                    :label="newItem.consignmentTrigger"
                                    outlined
                                    trueIcon="mdi-boom-gate-up"
                                    trueValue="Auto"
                                    v-model="newItem.consignmentTrigger" />
                            </v-col>
                        </v-row>
                        
                        <v-divider class="my-4" />

                        <v-subheader>Provisioning Stock</v-subheader>

                        <p>
                            Once a stock consignment has a supply location, stock can be assigned to it from that location.  
                        </p>

                        <v-row>
                            <v-col cols="12" md="6" lg="4">
                                <BT-Square-Check
                                    falseClass="success--text"
                                    falseIcon="mdi-boom-gate"
                                    falseValue="Manual"
                                    isEditing
                                    :label="newItem.provisionTrigger"
                                    outlined
                                    trueIcon="mdi-boom-gate-up"
                                    trueValue="Auto"
                                    v-model="newItem.provisionTrigger" />
                            </v-col>
                        </v-row>

                        <v-divider class="my-4" />

                        <v-subheader>Provisioning Strategy</v-subheader>

                        <p>
                            BlitzIt Web can use a couple different strategies when deciding what stock to use first.  You can decide this below.
                        </p>

                        <v-row>
                            <v-col cols="12" md="6" lg="4">
                                <BT-Square-Check
                                    falseClass="success--text"
                                    falseIcon="mdi-sync-circle"
                                    falseLabel="Freshest"
                                    falseValue="Freshest"
                                    isEditing
                                    outlined
                                    trueIcon="mdi-refresh-circle"
                                    trueLabel="First In First Out"
                                    trueValue="FirstInFirstOut"
                                    v-model="newItem.provisionStrategy" />
                            </v-col>
                        </v-row>

                        <BT-Btn
                            buttonClass="primary mx-1 mt-4"
                            inline
                            label="Next"
                            @click="stage += 1" />
                    </Setup-Block>
                    
                </v-tab-item>
                
                <v-tab-item>
                    <BT-Order-Cycle-Header showTrimmings highlightPackageConsign title="Order Pipeline: Package Consignment" />

                    <v-divider class="my-4" />
                    
                    <Setup-Block>
                        <v-subheader>Package Consignment</v-subheader>

                        <p>
                            Creating package consignments is the next step in the pipeline.  
                            These consignments can have packages assigned to them and you can request a courier to pickup and/or deliver.  
                        </p>

                        <p>(This only applies if you've decided to define this for individual customers rather than globally for everyone)</p>

                        <v-row>
                            <v-col cols="12" md="6" lg="4">
                                <BT-Square-Check
                                    falseClass="success--text"
                                    falseIcon="mdi-boom-gate"
                                    falseValue="Manual"
                                    isEditing
                                    :label="newItem.dispatchRequestTrigger"
                                    outlined
                                    trueIcon="mdi-boom-gate-up"
                                    trueValue="Auto"
                                    v-model="newItem.dispatchRequestTrigger" />
                            </v-col>
                        </v-row>
                        
                        <v-divider class="my-4" />

                        <v-subheader>Provisioning Stock</v-subheader>

                        <p>
                            You might only want the order pipeline to proceed if all the stock for the order is assigned to it - especially if you use a make-to-stock (MTS) approach.  
                            Alternatively, if you make-to-order (MTO) you might want BlitzIt Web to proceed to a package consignment regardless of whether there is enough stock for the order.  
                        </p>
                        
                        <v-row>
                            <v-col cols="12" md="6" lg="4">
                                <BT-Square-Check
                                    falseClass="success--text"
                                    falseIcon="mdi-boom-gate-arrow-up"
                                    falseLabel="Ignore Stock Provisioning"
                                    isEditing
                                    outlined
                                    trueIcon="mdi-boom-gate-alert"
                                    trueLabel="Must Be Fully Provisioned"
                                    v-model="newItem.mustFullyProvision" />
                            </v-col>
                        </v-row>

                        <BT-Btn
                            buttonClass="primary mx-1 mt-4"
                            inline
                            label="Next"
                            @click="stage += 1" />
                    </Setup-Block>
                    
                </v-tab-item>
                
                <v-tab-item>
                    <BT-Order-Cycle-Header showTrimmings highlightReadyForCourier title="Order Pipeline: Readying To Courier" />

                    <v-divider class="my-4" />
                    
                    <Setup-Block>
                        <v-subheader>Ready To Courier</v-subheader>

                        <p>
                            Package consignments need to be marked as ready for couriering in order to progress through the pipeline further.  
                            Once it is ready, you can request a courier to deliver the consignment.  
                            This can be done automatically by BlitzIt Web or it can be done manually by a user.  
                        </p>

                        <p>(This only applies if you've decided to define this for individual customers rather than globally for everyone)</p>

                        <v-row>
                            <v-col cols="12" md="6" lg="4">
                                <BT-Square-Check
                                    falseClass="success--text"
                                    falseIcon="mdi-boom-gate"
                                    falseValue="Manual"
                                    isEditing
                                    :label="newItem.consignmentDispatchTrigger"
                                    outlined
                                    trueIcon="mdi-boom-gate-up"
                                    trueValue="Auto"
                                    v-model="newItem.consignmentDispatchTrigger" />
                            </v-col>
                        </v-row>
                        
                        <BT-Btn
                            buttonClass="primary mx-1 mt-4"
                            inline
                            label="Next"
                            @click="stage += 1" />
                    </Setup-Block>
                    
                </v-tab-item>

                <v-tab-item>
                    <BT-Order-Cycle-Header showTrimmings highlightRequestCourier title="Order Pipeline: Request Courier" />

                    <v-divider class="my-4" />

                    <Setup-Block>
                        <v-subheader>Requesting A Courier</v-subheader>

                        <p>
                            Once the consignment is ready, you can request a courier (either yourself or a third party) to pickup and/or deliver it.  
                            BlitzIt Web can be configured to do this automatically or manually via user input.  
                        </p>

                        <p>(This only applies if you've decided to define this for individual customers rather than globally for everyone)</p>

                        <v-row>
                            <v-col cols="12" md="6" lg="4">
                                <BT-Square-Check
                                    falseClass="success--text"
                                    falseIcon="mdi-boom-gate"
                                    falseValue="Manual"
                                    isEditing
                                    :label="newItem.courierSelectionTriggerAsSupplier"
                                    outlined
                                    trueIcon="mdi-boom-gate-up"
                                    trueValue="Auto"
                                    v-model="newItem.courierSelectionTriggerAsSupplier" />
                            </v-col>
                        </v-row>

                        <v-divider class="my-4" />

                        <v-subheader>Pickup Only</v-subheader>

                        <p>
                            You can opt to have all of your customers pickup rather than organize a courier to pickup and deliver.  
                        </p>
                        
                        <v-row>
                            <v-col cols="12" md="6" lg="4">
                                <BT-Square-Check
                                    falseClass="success--text"
                                    falseIcon="mdi-truck"
                                    falseLabel="Deliver"
                                    isEditing
                                    outlined
                                    trueIcon="mdi-clipboard-arrow-up"
                                    trueLabel="Pickup"
                                    v-model="newItem.isPickup" />
                            </v-col>
                        </v-row>

                        <BT-Btn
                            buttonClass="primary mx-1 mt-4"
                            inline
                            label="Next"
                            @click="stage += 1" />
                    </Setup-Block>
                    
                </v-tab-item>
                
                <v-tab-item>
                    <Setup-Block>
                        <p>Do you want to limit what products this customer has access to?</p>
                    
                        <BT-Select-List-Box
                            @change="stage += 1"
                            v-model="newItem.productOfferingGroupID"
                            height="35vh"
                            isEditing
                            isSelectingNullValue
                            itemText="groupName"
                            label="Product Group"
                            :multiple="false"
                            navigation="product-groups" />

                        <BT-Btn
                            buttonClass="primary mx-1 mt-4"
                            inline
                            label="Next"
                            @click="stage += 1" />
                    </Setup-Block>
                </v-tab-item>

                <v-tab-item>
                    <Setup-Block>
                        <p>Do you want to apply certain ordering rules for this customer?</p>

                        <BT-List-Item-Check
                            class="my-3"
                            :falseValue="true"
                            subtitle="Use no ordering rules."
                            title="No Rules"
                            :trueValue="false"
                            v-model="newItem.useOrderItemRules" />

                        <BT-List-Item-Check
                            class="my-3"
                            subtitle="When placing new orders, use rules in order to control minimum order quantities, increments, and values."
                            title="Use Order Rules"
                            v-model="newItem.useOrderItemRules" />

                        <v-slide-x-transition>
                            <BT-Select-List-Box
                                v-if="newItem.useOrderItemRules"
                                v-model="newItem.orderItemRulesGroupID"
                                height="35vh"
                                isEditing
                                isSelectingNullValue
                                itemText="groupName"
                                label="Rules"
                                :multiple="false"
                                navigation="ordering-rules-groups" />
                        </v-slide-x-transition>

                        <BT-Btn
                            buttonClass="primary mx-1 mt-4"
                            inline
                            label="Save"
                            @click="save" />
                    </Setup-Block>
                </v-tab-item>

            </v-tabs-items>
        </template>
    </Setup-Step>
</template>

<script>
export default {
    name: 'Customer-Template-Setup',
    components: {
        BTSelectListBox: () => import('~components/BT-Select-List-Box.vue'),
        BTListItemCheck: () => import('~components/BT-List-Item-Check.vue'),
        BTOrderCycleHeader: () => import('~components/BT-Order-Cycle-Header.vue'),
        SetupBlock: () => import('~home/setup/Setup-Block.vue'),
        SetupMore: () => import('~home/setup/Setup-More.vue'),
        SetupStep: () => import('~home/setup/Setup-Step.vue'),
    },
    data() {
        return {
            loadingMsg: null,
            newItem: {},
            refreshList: false,
            stage: 0
        }
    },
    props: {
        settings: { //{ currentIndex: 0, stepperPlan: [], currentStep: 0 }
            type: Object,
            default: null
        },
    },
    methods: {
        addNew(j) {
            this.newItem = j || {
                approvalTrigger: 'Auto',
                fulfillerTrigger: 'Auto',
                processTrigger: 'Auto',
                consignmentTrigger: 'Auto',
                provisionTrigger: 'Auto',
                provisionStrategy: 'FirstInFirstOut',
                dispatchRequestTrigger: 'Auto',
                mustFullyProvision: false,
                consignmentDispatchTrigger: 'Auto',
                courierSelectionTriggerAsSupplier: 'Auto',
                isPickup: false
            };
            this.stage += 1;
        },
        nextStage(obj, key, val, stageNum = 1) {
            obj[key] = val;
            this.stage += stageNum;
        },
        async save() {
            try {
                this.loadingMsg = 'Saving';
                var res = null;

                if (this.newItem.id == null) {
                    res = await this.$BlitzIt.store.post('customer-templates', this.newItem);
                    
                    //wait a couple seconds
                    await this.twiddleThumbs(2000);
                }
                else {
                    res = await this.$BlitzIt.store.patch('customer-templates', this.newItem);
                }
                
                this.newItem.id = res.id;
                this.newItem.rowVersion = res.rowVersion;

                this.stage = 0;
                this.refreshList = !this.refreshList;
            }
            finally {
                this.loadingMsg = null;
            }
            
        },
        nextOrderingStyle() {
            if (this.newItem.orderingArrangement == 'Slotted') {
                this.stage += 4;
            }
            else {
                this.stage += 1;
            }
        },
        setScheduleLimits() {
            if (this.newItem.scheduleOrderLimit == 0) {
                this.newItem.scheduleOrderLimit = null;
            }
            if (this.newItem.scheduleDayLimit == 0) {
                this.newItem.scheduleDayLimit = null;
            }

            this.stage += 1;
        }
    }
}
</script>